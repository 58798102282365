<template>
  <NavBar/>
</template>

<script>
import NavBar from './components/NavBar.vue'

export default {
  name: 'HomePage',
  components: {
    NavBar
  }
}
</script>
