<template>
 <HeroRight/>
 <FeatureRight/>
 <FeatureLeft/>
 <FeatureCenter/>
</template>

<script>
import HeroRight from './components/HeroRight.vue'
import FeatureRight from './components/FeatureRight.vue'
import FeatureLeft from './components/FeatureLeft.vue'
import FeatureCenter from './components/FeatureCenter.vue'

export default {
 name: 'HomePage',
 components: {
   HeroRight,
   FeatureRight,
   FeatureLeft,
   FeatureCenter
 }
}
</script>